import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export const routes: Routes = [
  {
    path: 'finance',
    loadChildren: () =>
      import('./finance/finance.module').then((m) => m.FinanceModule),
  },
  {
    path: 'diet',
    loadChildren: () => import('./diet/diet.module').then((m) => m.DietModule),
  },
  {
    path: 'workout',
    loadChildren: () =>
      import('./workout/workout.module').then((m) => m.WorkoutModule),
  },
  {
    path: 'leads',
    loadChildren: () =>
      import('./leads/leads.module').then((m) => m.LeadsModule),
  },
  // { path: "error", component: ErrorComponent, data: { breadcrumb: "Error" } },
  // { path: "**", component: NotFoundComponent },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      onSameUrlNavigation: 'reload', // Added to solve refresh issue
      // useHash: true
    }),
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
